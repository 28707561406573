@font-face {
  font-family: "noigrotesk";
  src: url("./noi-grotesk/NoiGrotesk-Regular.ttf") format("truetype");
  font-display: block;
  font-style: normal;
  font-weight: normal;
}

body {
  background: #232832;
  color: white;
  font-family: "noigrotesk", Arial, sans-serif;
  letter-spacing: 0em; /* adjust letter spacing depending on font size */
  margin: 0;
  padding: 0;
  word-spacing: 0em; /* adjust word spacing depending on font size */
  -webkit-font-smoothing: antialiased; /* looks better */
  -moz-osx-font-smoothing: grayscale; /* looks better */
}

h1 {
  font-style: normal;
  font-weight: 400;
  font-size: 88px;
  line-height: 97px;
}
h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 56px;
  line-height: 70px;
}
h3 {
  font-style: normal;
  font-weight: 400;
  font-size: 38px;
  line-height: 48px;
}
h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 21px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}
h5 {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
}
h6 {
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 27px;
}
a {
  color: white;
  text-decoration: underline;
}
textarea:focus,
input:focus {
  outline: none;
}
